#User {
  &.cd-user-container {
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    overflow: hidden;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cd-user-cont {
    width: 70%;
  }
  .cd-user-cont-full {
    width: 100%;
  }

  .bx--tile {
    background-color: #ffffff;
    padding: 1rem 0 !important;
  }

  .cd-btn-out {
    display: flex;
    justify-content: flex-end;
  }

}
#Admin {
  &.cd-admin-container {
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    overflow: hidden;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cd-admin-cont {
    width: 70%;
    min-width: 320px;
  }

  .cd-btn-add {
    display: flex;
    justify-content: flex-end;
  }

  .bx--grid {
    padding-right: 0;
    padding-left: 1rem;
  }

}
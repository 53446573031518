#Login {
  &.cd-login-container {
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    overflow: hidden;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cd-login-cont {
    width: 35%;
    min-width: 320px;
  }

  .bx--tile {
    background-color: #ffffff;
  }

  .cd-form-cont {
    background-color: #ffffff !important;
  }
  
  .cd-register-cont {
    height: 100%;
    justify-content: space-around;
  }
  
  .cd-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .bx--form {
    align-items: center;
  }
  
  .cd-register-title {
    width: 80%;
  }

}

.bx--modal-content {
  padding-right: 1rem !important;

  .cd-modal-body {
    margin-top: 16px;
  }

  img {
    width: 100%;
  }
  
}

.flatpickr-calendar {
  z-index: 9999;
  position: absolute;
}

@import './styles/index.scss';
@import 'carbon-components/scss/globals/scss/styles.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh !important;
}

#root {
  height: 100vh !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
